import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Container, Row, Col, Button } from 'reactstrap'
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-Disclaimer-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-Disclaimer-2x.png'
import LinkBack from '../components/link-back'

class DisclaimerTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      overrideCookie: false,
    }
  }

  render() {
    const post = get(this.props, 'data.contentfulBasicPage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const slug = get(this.props, 'data.contentfulBasicPage.slug')
    const metaDescription = get(
      this,
      'props.data.contentfulBasicPage.metaDescription'
    )
    const metaImageURL = get(
      this,
      'props.data.contentfulBasicPage.metaImage.file.url'
    )
    return (
      <Layout
        override={this.state.overrideCookie}
        location={this.props.location}
      >
        <div style={{ background: '#fff' }}>
          <Helmet title={post.title + ' | ' + siteTitle}>
            <meta name="description" content={metaDescription} />
            <meta
              property="og:url"
              content={`https://cleanairdev.co.uk/${post.slug}`}
            />
            <meta property="og:type" content={'website'} />
            <meta
              property="og:title"
              content={post.title + ' | ' + siteTitle}
            />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImageURL} />
          </Helmet>{' '}
          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />
            <h1>{post.title}</h1>
          </div>
          {(slug === 'gmca-reports' || slug === 'technical-documents') && (
            <Container>
              <LinkBack
                to={'/clean-air-plans'}
                title={'back to Clean Air plans'}
              />
            </Container>
          )}
          <Container
            className={`news ${
              slug === 'technical-documents' ? 'technical-documents' : ''
            }  mt-5 mb-5`}
          >
            <Row>
              <Col>
                {post.text && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.text.childMarkdownRemark.html,
                    }}
                  />
                )}
              </Col>
            </Row>
            {slug === 'cookies' && (
              <Row className="mt-4">
                <Col>
                  <Button
                    className="small"
                    color="black"
                    onClick={() => {
                      this.setState({
                        overrideCookie: !this.state.overrideCookie,
                      })
                    }}
                  >
                    Manage cookies
                  </Button>
                </Col>
              </Row>
            )}

            {slug === 'clean-air-zone-map' && (
              <Row className="mt-4">
                <Col>
                  <iframe
                    style={{ width: '100%', height: '800px', border: 'none' }}
                    src="https://mappinggm.org.uk/clean-air-plan/#os_maps_light/10/53.5069/-2.3201"
                  ></iframe>
                  <p>
                    © Crown copyright and database rights 2018 OS 0100022610.
                    Use of this data is subject to{' '}
                    <Link to={'/ordnance-survey-copyright-notice'}>
                      terms and conditions
                    </Link>
                    .
                  </p>
                </Col>
              </Row>
            )}

            {slug === 'clean-air-zone-map' && (
              <Row className="mt-4">
                <Col>
                  <h2>About the roads within the Clean Air Zone</h2>
                  <p>
                    The Clean Air Zone boundary will follow the administrative
                    boundary of Greater Manchester as closely as possible. It
                    excludes the Strategic Road Network (SRN) of motorways and
                    major trunk roads which is managed by Highways England. The
                    boundary has been set with the principle that signage will
                    be clear, so that vehicle drivers who do not wish to enter
                    the Zone can take an alternative route.
                  </p>
                  <p>
                    The ten Greater Manchester local authorities continue to ask
                    the government to direct Highways England to tackle nitrogen
                    dioxide (NO2) exceedances on the SRN in the same way Greater
                    Manchester local authorities are having to take action on
                    the local road network.
                  </p>
                  <h2>A555</h2>
                  <p>
                    A decision has been taken to exclude a small stretch of the
                    A555 from the junction with the B5166 in the west to the
                    junction with A523 in the east (from Styal Road to the
                    Macclesfield Road junction). This is to enable movements
                    between Poynton and Handforth (towns in the district of
                    Cheshire East), to continue uncharged, as implementing a
                    charge is expected to result in local journeys returning to
                    the roads that the A555 was designed to reduce.
                  </p>
                  <h2>A628/A57</h2>
                  <p>
                    Tameside MBC has highlighted to Ministers that the
                    inconsistency in approach is leaving many of their most
                    vulnerable residents unprotected, particularly around the
                    A628/A57. This is a strategically important trans-Pennine
                    route that passes through the villages of Hollingworth and
                    Mottram as a single carriageway. This route, managed by
                    Highways England, would be left with NO2 exceedances that
                    are not being addressed, despite the area being declared as
                    part of GM’s Air Quality Management Area.
                  </p>
                  <p>
                    Ministers have agreed to the inclusion of the identified
                    section of the A57 and A628 within the Greater Manchester
                    Clean Air Zone – and that Government will work
                    collaboratively with Tameside MBC, TfGM and Highways England
                    to establish the most appropriate solution for the charging
                    mechanism to be applied to this section of road. As a
                    result, this stretch of road may not be included from the
                    start of the Clean Air Zone operation.
                  </p>
                  <h2>A575 / A580 in Worsley</h2>
                  <p>
                    Following consultation feedback, a minor modification is
                    also proposed to the Clean Air Zone boundary to include
                    sections of the A575 and A580 in the Worsley area. A
                    six-week public consultation on this proposal will take
                    place in September/October 2021. Check back for more or sign
                    up to the CleanAirGM newsletter for updates.
                  </p>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </Layout>
    )
  }
}

export default DisclaimerTemplate

export const pageQuery = graphql`
  query BasicBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBasicPage(slug: { eq: $slug }) {
      id
      slug
      title
      metaDescription
      metaImage {
        file {
          url
        }
      }
      text {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
